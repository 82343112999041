<template>
  <div class="content">
    <div class="title-container">
      <BaseH1 :html="title" :short="true" />
      <TextDescription :text="description" />
    </div>
    <SvgIllustrationsEmptySpace class="empty-img" />
    <ButtonPrimary
      :to="{ name: ROUTE_ACCOUNT_PREMIUM_NAME }"
      :label="'Dokonaj zakup'"
    />
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import ButtonPrimary from "@/components/UI/Base/ButtonPrimary.vue";
import TextDescription from "@/components/UI/TextDescription.vue";
import { ROUTE_ACCOUNT_PREMIUM_NAME } from "@/router/constants";
import SvgIllustrationsEmptySpace from "@/components/Svg/Illustrations/SvgIllustrationsEmptySpace.vue";

export default {
  components: {
    BaseH1,
    ButtonPrimary,
    TextDescription,
    SvgIllustrationsEmptySpace,
  },

  setup() {
    const state = reactive({
      title: "Oops..",
      description: "Płatność nie została zrealizowana.\nSprobuj ponownie",
    });

    return {
      ...toRefs(state),
      ROUTE_ACCOUNT_PREMIUM_NAME,
    };
  },
};
</script>

<style scoped>
.content {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 60px;
}
.title-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 40px;
}
.empty-img {
  width: 280px;
  height: auto;
}

@media (max-width: 1200px) {
  .content {
    row-gap: 60px;
  }
  .title-container {
    row-gap: 40px;
  }
  .empty-img {
    width: 223px;
    height: auto;
  }
}
</style>
